export var PREFIX = 'flowchart-editor';
export var INIT_FLOW_DATA = {
  "nodes": [{
    "id": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
    "renderKey": "start-action-node",
    "name": "start-action-node",
    "label": "",
    "width": 48,
    "height": 48,
    "ports": {
      "items": [{
        "id": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
        "group": "right",
        "type": "output"
      }],
      "groups": {
        "right": {
          "position": {
            "name": "right"
          },
          "attrs": {
            "circle": {
              "r": 5,
              "magnet": true,
              "stroke": "#31d0c6",
              "strokeWidth": 1,
              "fill": "#fff",
              "style": {
                "visibility": "visible"
              }
            }
          },
          "zIndex": 10
        }
      }
    },
    "originData": {
      "name": "start-action-node",
      "width": 48,
      "height": 48,
      "ports": {
        "items": [{
          "id": "a509ea6e-68a5-434f-838e-3b5ca60b5e4d",
          "group": "right",
          "type": "output"
        }],
        "groups": {
          "right": {
            "position": {
              "name": "right"
            },
            "attrs": {
              "circle": {
                "r": 5,
                "magnet": true,
                "stroke": "#31d0c6",
                "strokeWidth": 1,
                "fill": "#fff",
                "style": {
                  "visibility": "visible"
                }
              }
            },
            "zIndex": 10
          }
        }
      },
      "parentKey": "workflow-actions"
    },
    "isCustom": true,
    "parentKey": "workflow-actions",
    "x": 410,
    "y": 90,
    "zIndex": 10,
    "incomingEdges": null,
    "outgoingEdges": [{
      "shape": "edge",
      "attrs": {
        "line": {
          "stroke": "#A2B1C3",
          "strokeWidth": 1,
          "targetMarker": {
            "name": "block",
            "width": 12,
            "height": 8
          },
          "strokeDasharray": "0 0"
        }
      },
      "id": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019:0a8f205d-0ac0-4d56-8ba8-2022ca77aa48-node-40942cc4-23b4-4e32-b23f-e9b2e00e5725:e2f06a49-e856-4e64-a82b-0f05f2733635",
      "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
      "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
      "zIndex": 1,
      "data": {
        "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
        "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
        "source": {
          "cell": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
          "port": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48"
        },
        "target": {
          "cell": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
          "port": "e2f06a49-e856-4e64-a82b-0f05f2733635"
        },
        "attrs": {
          "line": {
            "stroke": "#A2B1C3",
            "targetMarker": {
              "name": "block",
              "width": 12,
              "height": 8
            },
            "strokeDasharray": "0 0",
            "strokeWidth": 1
          }
        },
        "zIndex": 1,
        "data": {
          "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
          "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
          "source": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
          "target": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
          "attrs": {
            "line": {
              "stroke": "#A2B1C3",
              "targetMarker": {
                "name": "block",
                "width": 12,
                "height": 8
              },
              "strokeDasharray": "0 0",
              "strokeWidth": 1
            }
          }
        },
        "id": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019:0a8f205d-0ac0-4d56-8ba8-2022ca77aa48-node-40942cc4-23b4-4e32-b23f-e9b2e00e5725:e2f06a49-e856-4e64-a82b-0f05f2733635",
        "sourcePort": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
        "targetPort": "e2f06a49-e856-4e64-a82b-0f05f2733635"
      },
      "source": {
        "cell": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
        "port": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48"
      },
      "target": {
        "cell": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
        "port": "e2f06a49-e856-4e64-a82b-0f05f2733635"
      },
      "labels": [{
        "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
        "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
        "source": {
          "cell": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
          "port": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48"
        },
        "target": {
          "cell": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
          "port": "e2f06a49-e856-4e64-a82b-0f05f2733635"
        },
        "attrs": {
          "line": {
            "stroke": "#A2B1C3",
            "targetMarker": {
              "name": "block",
              "width": 12,
              "height": 8
            },
            "strokeDasharray": "0 0",
            "strokeWidth": 1
          }
        },
        "zIndex": 1,
        "data": {
          "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
          "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
          "source": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
          "target": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
          "attrs": {
            "line": {
              "stroke": "#A2B1C3",
              "targetMarker": {
                "name": "block",
                "width": 12,
                "height": 8
              },
              "strokeDasharray": "0 0",
              "strokeWidth": 1
            }
          }
        },
        "id": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019:0a8f205d-0ac0-4d56-8ba8-2022ca77aa48-node-40942cc4-23b4-4e32-b23f-e9b2e00e5725:e2f06a49-e856-4e64-a82b-0f05f2733635",
        "sourcePort": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
        "targetPort": "e2f06a49-e856-4e64-a82b-0f05f2733635"
      }]
    }]
  }, {
    "id": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
    "renderKey": "end-action-node",
    "name": "end-action-node",
    "label": "",
    "width": 48,
    "height": 48,
    "ports": {
      "items": [{
        "id": "e2f06a49-e856-4e64-a82b-0f05f2733635",
        "group": "left",
        "type": "input"
      }],
      "groups": {
        "left": {
          "position": {
            "name": "left"
          },
          "attrs": {
            "circle": {
              "r": 5,
              "magnet": "passive",
              "stroke": "#31d0c6",
              "strokeWidth": 1,
              "fill": "#31d0c6",
              "style": {
                "visibility": "visible"
              }
            }
          },
          "zIndex": 10
        }
      }
    },
    "originData": {
      "name": "end-action-node",
      "width": 48,
      "height": 48,
      "ports": {
        "items": [{
          "id": "9abe29ae-e7e5-4284-82ff-ea96eb6ab933",
          "group": "left",
          "type": "input"
        }],
        "groups": {
          "left": {
            "position": {
              "name": "left"
            },
            "attrs": {
              "circle": {
                "r": 5,
                "magnet": "passive",
                "stroke": "#31d0c6",
                "strokeWidth": 1,
                "fill": "#31d0c6",
                "style": {
                  "visibility": "visible"
                }
              }
            },
            "zIndex": 10
          }
        }
      },
      "parentKey": "workflow-actions"
    },
    "isCustom": true,
    "parentKey": "workflow-actions",
    "x": 604,
    "y": 88,
    "zIndex": 10,
    "incomingEdges": [{
      "shape": "edge",
      "attrs": {
        "line": {
          "stroke": "#A2B1C3",
          "strokeWidth": 1,
          "targetMarker": {
            "name": "block",
            "width": 12,
            "height": 8
          },
          "strokeDasharray": "0 0"
        }
      },
      "id": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019:0a8f205d-0ac0-4d56-8ba8-2022ca77aa48-node-40942cc4-23b4-4e32-b23f-e9b2e00e5725:e2f06a49-e856-4e64-a82b-0f05f2733635",
      "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
      "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
      "zIndex": 1,
      "data": {
        "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
        "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
        "source": {
          "cell": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
          "port": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48"
        },
        "target": {
          "cell": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
          "port": "e2f06a49-e856-4e64-a82b-0f05f2733635"
        },
        "attrs": {
          "line": {
            "stroke": "#A2B1C3",
            "targetMarker": {
              "name": "block",
              "width": 12,
              "height": 8
            },
            "strokeDasharray": "0 0",
            "strokeWidth": 1
          }
        },
        "zIndex": 1,
        "data": {
          "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
          "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
          "source": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
          "target": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
          "attrs": {
            "line": {
              "stroke": "#A2B1C3",
              "targetMarker": {
                "name": "block",
                "width": 12,
                "height": 8
              },
              "strokeDasharray": "0 0",
              "strokeWidth": 1
            }
          }
        },
        "id": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019:0a8f205d-0ac0-4d56-8ba8-2022ca77aa48-node-40942cc4-23b4-4e32-b23f-e9b2e00e5725:e2f06a49-e856-4e64-a82b-0f05f2733635",
        "sourcePort": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
        "targetPort": "e2f06a49-e856-4e64-a82b-0f05f2733635"
      },
      "source": {
        "cell": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
        "port": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48"
      },
      "target": {
        "cell": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
        "port": "e2f06a49-e856-4e64-a82b-0f05f2733635"
      },
      "labels": [{
        "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
        "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
        "source": {
          "cell": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
          "port": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48"
        },
        "target": {
          "cell": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
          "port": "e2f06a49-e856-4e64-a82b-0f05f2733635"
        },
        "attrs": {
          "line": {
            "stroke": "#A2B1C3",
            "targetMarker": {
              "name": "block",
              "width": 12,
              "height": 8
            },
            "strokeDasharray": "0 0",
            "strokeWidth": 1
          }
        },
        "zIndex": 1,
        "data": {
          "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
          "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
          "source": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
          "target": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
          "attrs": {
            "line": {
              "stroke": "#A2B1C3",
              "targetMarker": {
                "name": "block",
                "width": 12,
                "height": 8
              },
              "strokeDasharray": "0 0",
              "strokeWidth": 1
            }
          }
        },
        "id": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019:0a8f205d-0ac0-4d56-8ba8-2022ca77aa48-node-40942cc4-23b4-4e32-b23f-e9b2e00e5725:e2f06a49-e856-4e64-a82b-0f05f2733635",
        "sourcePort": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
        "targetPort": "e2f06a49-e856-4e64-a82b-0f05f2733635"
      }]
    }],
    "outgoingEdges": null
  }],
  "edges": [{
    "id": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019:0a8f205d-0ac0-4d56-8ba8-2022ca77aa48-node-40942cc4-23b4-4e32-b23f-e9b2e00e5725:e2f06a49-e856-4e64-a82b-0f05f2733635",
    "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
    "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
    "source": {
      "cell": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
      "port": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48"
    },
    "target": {
      "cell": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
      "port": "e2f06a49-e856-4e64-a82b-0f05f2733635"
    },
    "attrs": {
      "line": {
        "stroke": "#A2B1C3",
        "targetMarker": {
          "name": "block",
          "width": 12,
          "height": 8
        },
        "strokeDasharray": "0 0",
        "strokeWidth": 1
      }
    },
    "zIndex": 1,
    "data": {
      "targetPortId": "e2f06a49-e856-4e64-a82b-0f05f2733635",
      "sourcePortId": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
      "source": "node-49a121bd-ecc8-47d5-87b4-ef7678ee2019",
      "target": "node-40942cc4-23b4-4e32-b23f-e9b2e00e5725",
      "attrs": {
        "line": {
          "stroke": "#A2B1C3",
          "targetMarker": {
            "name": "block",
            "width": 12,
            "height": 8
          },
          "strokeDasharray": "0 0",
          "strokeWidth": 1
        }
      }
    },
    "sourcePort": "0a8f205d-0ac0-4d56-8ba8-2022ca77aa48",
    "targetPort": "e2f06a49-e856-4e64-a82b-0f05f2733635"
  }]
};
export var FormItemHeight = 24;
export var defaultEdgeConfig = {
  attrs: {
    line: {
      stroke: '#A2B1C3',
      strokeWidth: 1,
      strokeDasharray: [0, 0]
    },
    text: {
      fill: '#000',
      fontSize: 12,
      textAnchor: 'middle',
      textVerticalAnchor: 'middle'
    }
  }
};
export var lineSolidConfig = {
  attrs: {
    line: {
      stroke: '#A2B1C3',
      targetMarker: 'classic',
      strokeDasharray: '0 0',
      strokeWidth: 1
    }
  }
};
export var lineDashConfig = {
  attrs: {
    line: {
      stroke: '#A2B1C3',
      targetMarker: 'classic',
      strokeDasharray: '5 5',
      strokeWidth: 1
    }
  }
};
export var ArrowConfig = {
  width: 12,
  height: 8,
  name: 'block'
};
export var DisableArrowConfig = {
  width: 0,
  height: 0,
  name: ''
};
export var ArrowMaps = {
  target: {
    sourceMarker: DisableArrowConfig,
    targetMarker: ArrowConfig
  },
  source: {
    sourceMarker: ArrowConfig,
    targetMarker: DisableArrowConfig
  },
  all: {
    sourceMarker: ArrowConfig,
    targetMarker: ArrowConfig
  },
  none: {
    sourceMarker: DisableArrowConfig,
    targetMarker: DisableArrowConfig
  }
};
export var ArrowStrokeMaps = {
  solid: [0, 0],
  dash: [5, 5]
};
export var WfActionType;
(function (WfActionType) {
  WfActionType["Start"] = "start";
  WfActionType["End"] = "end";
  WfActionType["ApprovalRequest"] = "sign";
  WfActionType["Condition"] = "condition";
  WfActionType["Countersign"] = "counterSign";
  WfActionType["Script"] = "script";
  WfActionType["ProvideInput"] = "submit";
})(WfActionType || (WfActionType = {}));