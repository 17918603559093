import _extends from "@babel/runtime/helpers/extends";
import { defaultOperatorNegationMap } from '../../defaults';
import { convertToIC } from '../convertQuery';
import { isRuleGroupType } from '../isRuleGroup';
import { fieldIsValidUtil, getFieldsArray, isPojo } from '../parserUtils';
import { isJsonLogicAnd, isJsonLogicBetweenExclusive, isJsonLogicBetweenInclusive, isJsonLogicDoubleNegation, isJsonLogicEqual, isJsonLogicGreaterThan, isJsonLogicGreaterThanOrEqual, isJsonLogicInArray, isJsonLogicInString, isJsonLogicLessThan, isJsonLogicLessThanOrEqual, isJsonLogicNegation, isJsonLogicNotEqual, isJsonLogicOr, isJsonLogicStrictEqual, isJsonLogicStrictNotEqual, isRQBJsonLogicEndsWith, isRQBJsonLogicStartsWith, isRQBJsonLogicVar } from './utils';
var emptyRuleGroup = {
  combinator: 'and',
  rules: []
};

/**
 * Converts a JsonLogic object into a query suitable for
 * the QueryBuilder component's `query` or `defaultQuery` props.
 */

function parseJsonLogic(rqbJsonLogic, options) {
  if (options === void 0) {
    options = {};
  }
  var listsAsArrays = !!options.listsAsArrays;
  var fieldsFlat = getFieldsArray(options.fields);
  var getValueSources = options.getValueSources;
  var fieldIsValid = function fieldIsValid(fieldName, operator, subordinateFieldName) {
    return fieldIsValidUtil({
      fieldName: fieldName,
      fieldsFlat: fieldsFlat,
      operator: operator,
      subordinateFieldName: subordinateFieldName,
      getValueSources: getValueSources
    });
  };

  // Overload 1: Always return a rule group or false for the outermost logic object

  // Implementation
  function processLogic(logic, outermost) {
    // Bail if the outermost logic is not an object
    if (outermost && typeof logic !== 'object') {
      return false;
    }
    var key = Object.keys(logic)[0];
    // @ts-expect-error `key in logic === true`, but TS doesn't know that
    var keyValue = logic[key];
    // Rule groups
    if (isJsonLogicAnd(logic)) {
      return {
        combinator: 'and',
        rules: logic.and.map(function (l) {
          return processLogic(l);
        }).filter(Boolean)
      };
    } else if (isJsonLogicOr(logic)) {
      return {
        combinator: 'or',
        rules: logic.or.map(function (l) {
          return processLogic(l);
        }).filter(Boolean)
      };
    } else if (isJsonLogicNegation(logic)) {
      var _rule = processLogic(logic['!']);
      if (_rule) {
        if (!isRuleGroupType(_rule) && (_rule.operator === 'between' || _rule.operator === 'in' || _rule.operator === 'contains' || _rule.operator === 'beginsWith' || _rule.operator === 'endsWith')) {
          return _extends({}, _rule, {
            operator: defaultOperatorNegationMap[_rule.operator]
          });
        } else if (isJsonLogicBetweenExclusive(logic['!']) && isRuleGroupType(_rule)) {
          return _extends({}, _rule, {
            not: true
          });
        }
        return {
          combinator: 'and',
          rules: [_rule],
          not: true
        };
      }
      return false;
    } else if (isJsonLogicDoubleNegation(logic)) {
      var _rule2 = processLogic(logic['!!']);
      return _rule2 || false;
    }

    // All other keys represent rules
    var rule = false;
    var field = '';
    var operator = '=';
    var value = '';
    var valueSource = undefined;

    // Basic boolean operations
    if (isJsonLogicEqual(logic) || isJsonLogicStrictEqual(logic) || isJsonLogicNotEqual(logic) || isJsonLogicStrictNotEqual(logic) || isJsonLogicGreaterThan(logic) || isJsonLogicGreaterThanOrEqual(logic) || isJsonLogicLessThan(logic) || isJsonLogicLessThanOrEqual(logic) || isJsonLogicInString(logic) || isRQBJsonLogicStartsWith(logic) || isRQBJsonLogicEndsWith(logic)) {
      var first = keyValue[0],
        second = keyValue[1];
      if (isRQBJsonLogicVar(first) && !isPojo(second)) {
        field = first["var"];
        value = second;
      } else if (!isPojo(first) && isRQBJsonLogicVar(second)) {
        field = second["var"];
        value = first;
      } else if (isRQBJsonLogicVar(first) && isRQBJsonLogicVar(second)) {
        field = first["var"];
        value = second["var"];
        valueSource = 'field';
      } else {
        return false;
      }

      // Translate operator if necessary
      if (isJsonLogicEqual(logic) || isJsonLogicStrictEqual(logic)) {
        operator = value === null ? 'null' : '=';
      } else if (isJsonLogicNotEqual(logic) || isJsonLogicStrictNotEqual(logic)) {
        operator = value === null ? 'notNull' : '!=';
      } else if (isJsonLogicInString(logic)) {
        operator = 'contains';
      } else if (isRQBJsonLogicStartsWith(logic)) {
        operator = 'beginsWith';
      } else if (isRQBJsonLogicEndsWith(logic)) {
        operator = 'endsWith';
      } else {
        operator = key;
      }
      if (fieldIsValid(field, operator, valueSource === 'field' ? value : undefined)) {
        rule = {
          field: field,
          operator: operator,
          value: value,
          valueSource: valueSource
        };
      }
    } else if (isJsonLogicBetweenExclusive(logic) && isRQBJsonLogicVar(logic['<'][1])) {
      field = logic['<'][1]["var"];
      var values = [logic['<'][0], logic['<'][2]];
      // istanbul ignore else
      if (values.every(isRQBJsonLogicVar) || values.every(function (el) {
        return typeof el === 'string';
      }) || values.every(function (el) {
        return typeof el === 'number';
      }) || values.every(function (el) {
        return typeof el === 'boolean';
      })) {
        return processLogic({
          and: [{
            '>': [{
              "var": field
            }, values[0]]
          }, {
            '<': [{
              "var": field
            }, values[1]]
          }]
        }) || /* istanbul ignore next */false;
      }
    } else if (isJsonLogicBetweenInclusive(logic) && isRQBJsonLogicVar(logic['<='][1])) {
      field = logic['<='][1]["var"];
      operator = 'between';
      var _values = [logic['<='][0], logic['<='][2]];
      if (logic['<='].every(isRQBJsonLogicVar)) {
        var vars = _values;
        valueSource = 'field';
        var fieldList = vars.map(function (el) {
          return el["var"];
        }).filter(function (sf) {
          return fieldIsValid(field, operator, sf);
        });
        value = listsAsArrays ? fieldList : fieldList.join(',');
      } else {
        // istanbul ignore else
        if (_values.every(function (el) {
          return typeof el === 'string';
        }) || _values.every(function (el) {
          return typeof el === 'number';
        }) || _values.every(function (el) {
          return typeof el === 'boolean';
        })) {
          value = listsAsArrays ? _values : _values.map(function (el) {
            return "" + el;
          }).join(',');
        }
      }
      if (fieldIsValid(field, operator) && value.length >= 2) {
        rule = {
          field: field,
          operator: operator,
          value: value,
          valueSource: valueSource
        };
      }
    } else if (isJsonLogicInArray(logic) && isRQBJsonLogicVar(keyValue[0])) {
      field = keyValue[0]["var"];
      operator = 'in';
      if (logic["in"][1].every(isRQBJsonLogicVar)) {
        valueSource = 'field';
        var _fieldList = logic["in"][1].map(function (el) {
          return el["var"];
        }).filter(function (sf) {
          return fieldIsValid(field, operator, sf);
        });
        value = listsAsArrays ? _fieldList : _fieldList.join(',');
      } else {
        // istanbul ignore else
        if (logic["in"][1].every(function (el) {
          return typeof el === 'string';
        }) || logic["in"][1].every(function (el) {
          return typeof el === 'number';
        }) || logic["in"][1].every(function (el) {
          return typeof el === 'boolean';
        })) {
          value = listsAsArrays ? logic["in"][1] : logic["in"][1].map(function (el) {
            return "" + el;
          }).join(',');
        }
      }

      // istanbul ignore else
      if (value.length > 0) {
        rule = {
          field: field,
          operator: operator,
          value: value,
          valueSource: valueSource
        };
      }
    }
    return !rule ? false : outermost ? {
      combinator: 'and',
      rules: [rule]
    } : rule;
  }
  var logicRoot = rqbJsonLogic;
  if (typeof rqbJsonLogic === 'string') {
    try {
      logicRoot = JSON.parse(rqbJsonLogic);
    } catch (err) {
      return emptyRuleGroup;
    }
  }
  var result = processLogic(logicRoot, true);
  var finalQuery = !result ? emptyRuleGroup : result;
  return options.independentCombinators ? convertToIC(finalQuery) : finalQuery;
}
export { parseJsonLogic };