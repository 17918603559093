import { isFormEvent, compatibleLegaoSchema, getNodeSchemaById } from '@digiforce/dvd-utils';
import { isNodeSchema } from '@digiforce/dvd-types';
export var utils = {
  isNodeSchema: isNodeSchema,
  isFormEvent: isFormEvent,
  compatibleLegaoSchema: compatibleLegaoSchema,
  getNodeSchemaById: getNodeSchemaById
};
export var PROP_VALUE_CHANGED_TYPE;
(function (PROP_VALUE_CHANGED_TYPE) {
  PROP_VALUE_CHANGED_TYPE["SET_VALUE"] = "SET_VALUE";
  PROP_VALUE_CHANGED_TYPE["SUB_VALUE_CHANGE"] = "SUB_VALUE_CHANGE";
})(PROP_VALUE_CHANGED_TYPE || (PROP_VALUE_CHANGED_TYPE = {}));