/** 数据源的状态 */
export var RuntimeDataSourceStatus;

/**
 * 运行时的数据源（对外暴露的接口）
 * @see https://yuque.antfin-inc.com/mo/spec/spec-low-code-building-schema#Jwgj5
 */
(function (RuntimeDataSourceStatus) {
  RuntimeDataSourceStatus["Initial"] = "init";
  RuntimeDataSourceStatus["Loading"] = "loading";
  RuntimeDataSourceStatus["Loaded"] = "loaded";
  RuntimeDataSourceStatus["Error"] = "error";
})(RuntimeDataSourceStatus || (RuntimeDataSourceStatus = {}));