import { isString, each, includes, isBoolean } from 'lodash';
import { isExpression, parseSingleExpression } from './utils';

/**
[
    {
        title: 'Node1',
        value: '0-0',
        children: [
            {
                title: 'Child Node1',
                value: '0-0-1',
            },
            {
                title: 'Child Node2',
                value: '0-0-2',
            },
        ],
    },
    {
        title: 'Node2',
        value: '0-1',
    },
];
*/
export var getTreeDataFromRecords = function getTreeDataFromRecords(records, nameField, parentField) {
  if (records === void 0) {
    records = [];
  }
  if (nameField === void 0) {
    nameField = "name";
  }
  if (parentField === void 0) {
    parentField = "parent";
  }
  var result = [],
    tempParentValue,
    rootNode;
  var getChildrenNodes = function getChildrenNodes(parent) {
    var nodes = [];
    records.forEach(function (record) {
      if (record[parentField] === parent) {
        var tempNode = {
          title: record[nameField],
          value: record._id,
          key: record._id
        };
        var tempChildren = getChildrenNodes(record._id);
        if (tempChildren && tempChildren.length) {
          tempNode.children = tempChildren;
        }
        nodes.push(tempNode);
      }
    });
    return nodes;
  };
  var getTreeNode = function getTreeNode(record) {
    tempParentValue = record[parentField];
    var isRoot = !tempParentValue;
    if (!isRoot) {
      var isParentExist = !!records.find(function (item) {
        return item._id === tempParentValue;
      });
      isRoot = !isParentExist;
    }
    if (isRoot) {
      var tempNode = {
        title: record[nameField],
        value: record._id,
        key: record._id
      };
      var tempChildren = getChildrenNodes(record._id);
      if (tempChildren && tempChildren.length) {
        tempNode.children = tempChildren;
      }
      return tempNode;
    }
  };
  records.forEach(function (record) {
    rootNode = getTreeNode(record);
    if (rootNode) {
      result.push(rootNode);
    }
  });
  return result;
};
export var getObjectButtons = function getObjectButtons(objectSchema, actionOn) {
  var buttons = [];
  each(objectSchema.actions, function (action, actionApiName) {
    if (actionOn && actionOn.length && !includes(actionOn, action.on)) {
      return;
    }
    var visible = false;
    if (isString(action._visible)) {
      try {
        var visibleFunction;
        var _visible = action._visible.trim();
        if (isExpression(_visible)) {
          // # Support page layout Write the Visible_ON function expression, see: the display conditions of the page layout button do not take effect # 3340
          visibleFunction = function visibleFunction(object_name, record_id, record_permissions, record) {
            var DGPlatform = window.DGPlatform;
            var userSession = DGPlatform && DGPlatform.USER_CONTEXT || {};
            var globalData = Object.assign({}, userSession, {
              now: new Date()
            });
            return parseSingleExpression(_visible, record, "#", globalData);
          };
        } else {
          visibleFunction = eval("(" + action._visible.replace(/_\./g, "window._.").replace(/\bt\(/g, "window.t(") + ")");
        }
        visible = function visible() {
          return typeof visibleFunction === "function" ? visibleFunction.apply(this, arguments) : visibleFunction;
        };
      } catch (error) {
        console.error(error, action._visible);
      }
    } else {
      if (isBoolean(action.visible)) {
        visible = action.visible;
      }
    }
    if (isBoolean(action._visible)) {
      visible = action._visible;
    }
    var todo = action._todo || action.todo;
    if (isString(todo) && (todo.startsWith("function") || todo.startsWith("async function"))) {
      try {
        todo = eval("(" + todo.replace(/_\./g, "window._.").replace(/\bt\(/g, "window.t(") + ")");
      } catch (error) {
        console.error(error, todo);
      }
    }
    buttons.push(Object.assign({}, action, {
      label: action.label,
      todo: todo,
      visible: visible
    }));
  });
  return buttons;
};
export var BAD_FILTERS = ["_id", "=", "__BAD_FILTERS"];
export { isExpression, parseSingleExpression } from './utils';