import * as Redux from 'redux';
import * as ReactRedux from 'react-redux';
import store from "../stores/configureStore";
import { receivePluginInstance, receivePluginComponent } from '../actions';
import { ObjectHomeIFrame, generateIFrame } from "../components/object_home_iframe";
// import _ from 'lodash';

/**
* Register a plugin to window
*/
export var registerPlugin = function registerPlugin(pluginName, pluginInstance) {
  store.dispatch(receivePluginInstance(pluginName, pluginInstance));
  var registry = new PluginRegistry(pluginName);
  pluginInstance.initialize(registry, store);
};
export var registerWindowLibraries = function registerWindowLibraries() {
  window["Redux"] = Redux;
  window["ReactRedux"] = ReactRedux;
  window["registerPlugin"] = registerPlugin;
};
function dispatchPluginComponentAction(name, pluginId, component, id) {
  if (id === void 0) {
    id = "";
  }
  if (!id) {
    id = generateId();
  }
  store.dispatch(receivePluginComponent(name, {
    id: id,
    pluginId: pluginId,
    component: component
  }));
  return id;
}
export function generateId() {
  // implementation taken from http://stackoverflow.com/a/2117523
  var id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  id = id.replace(/[xy]/g, function (c) {
    var r = Math.floor(Math.random() * 16);
    var v;
    if (c === 'x') {
      v = r;
    } else {
      v = r & 0x3 | 0x8;
    }
    return v.toString(16);
  });
  return id;
}
export var PluginRegistry = function PluginRegistry(id) {
  var _this = this;
  this.id = void 0;
  this.registerObjectHomeComponent = function (objectName, componentClass) {
    dispatchPluginComponentAction("ObjectHome", _this.id, componentClass, objectName);
  };
  this.registerObjectIFrameHomeComponent = function (objectName, url, componentClass) {
    if (componentClass) {
      componentClass = generateIFrame(url)(componentClass);
    } else {
      componentClass = generateIFrame(url)(ObjectHomeIFrame);
    }
    dispatchPluginComponentAction("ObjectHome", _this.id, componentClass, objectName);
  };
  this.registerDashboardComponent = function (appNames, componentClass) {
    if (!_.isArray(appNames)) {
      appNames = appNames.split(",");
    }
    appNames.forEach(function (item) {
      dispatchPluginComponentAction("Dashboard", _this.id, componentClass, item);
    });
  };
  this.registerDashboardIFrameComponent = function (appNames, url, componentClass) {
    if (componentClass) {
      componentClass = generateIFrame(url)(componentClass);
    } else {
      componentClass = generateIFrame(url)(ObjectHomeIFrame);
    }
    if (!_.isArray(appNames)) {
      appNames = appNames.split(",");
    }
    appNames.forEach(function (item) {
      dispatchPluginComponentAction("Dashboard", _this.id, componentClass, item);
    });
  };
  this.registerNotificationsComponent = function (name, componentClass) {
    dispatchPluginComponentAction("Notifications", _this.id, componentClass, name);
  };
  this.id = id;
}

/**
* Register a component that show a dashboard to the object home
*/;