import { boolean as _boolean } from './boolean';
import { currency } from './currency';
import { currency_range } from './currency_range';
import { date } from './date';
import { date_range } from './date_range';
import { datetime } from './datetime';
import { datetime_range } from './datetime_range';
import { time } from './time';
import { textarea } from './textarea';
import { html } from './html';
import { email } from './email';
import { number } from './number';
import { number_range } from './number_range';
import { percent } from './percent';
import { select } from './select';
import { toggle } from './toggle';
import { url } from './url';
import { lookup } from './lookup';
import { lookup_with_grid } from './lookup_with_grid';
import { master_detail } from './master_detail';
import { object } from './object';
import { grid } from './grid';
import { image } from './image';
import { file } from './file';
import { avatar } from './avatar';
import { formula } from './formula';
import { summary } from './summary';
import { autonumber } from './autonumber';
import { code } from './code';
import { password } from './password';
import { ComponentRegistry } from "@digiforce-ui/builder-store";
import { defaultsDeep } from 'lodash';
export var StandardValueTypes = {
  "boolean": _boolean,
  currency: currency,
  currency_range: currency_range,
  date: date,
  date_range: date_range,
  datetime: datetime,
  datetime_range: datetime_range,
  time: time,
  textarea: textarea,
  html: html,
  email: email,
  number: number,
  number_range: number_range,
  percent: percent,
  select: select,
  toggle: toggle,
  url: url,
  lookup: lookup,
  lookup_with_grid: lookup_with_grid,
  master_detail: master_detail,
  image: image,
  file: file,
  avatar: avatar,
  object: object,
  grid: grid,
  formula: formula,
  summary: summary,
  autonumber: autonumber,
  code: code,
  password: password
};
ComponentRegistry.valueTypes = defaultsDeep({}, ComponentRegistry.valueTypes, StandardValueTypes);