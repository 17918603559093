import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/extends";
var _excluded = ["propsSchema"],
  _excluded2 = ["schema"],
  _excluded3 = ["required"];
import { get, set, cloneDeep, isString } from 'lodash';
import { translate } from '@digiforce-ui/builder-sdk';

// {
//   parent: '#',
//   schema: ...,
//   children: []
// }

export function isUrl(string) {
  var protocolRE = /^(?:\w+:)?\/\/(\S+)$/;
  // const domainRE = /^[^\s\.]+\.\S{2,}$/;
  if (typeof string !== 'string') return false;
  return protocolRE.test(string);
}
export function isCheckBoxType(schema, readOnly) {
  if (readOnly) return false;
  if (schema.widget === 'checkbox') return true;
  if (schema && schema.type === 'boolean') {
    if (schema["enum"]) return false;
    if (schema.widget === undefined) return true;
    return false;
  }
}

// a[].b.c => a.b.c
function removeBrackets(string) {
  if (typeof string === 'string') {
    return string.replace(/\[\]/g, '');
  } else {
    return string;
  }
}
export function getParentPath(path) {
  if (typeof path === 'string') {
    var pathArr = path.split('.');
    if (pathArr.length === 1) {
      return '#';
    }
    pathArr.pop();
    return pathArr.join('.');
  }
  return '#';
}
export function getValueByPath(formData, path) {
  if (path === '#' || !path) {
    return formData || {};
  } else if (typeof path === 'string') {
    return get(formData, path);
  } else {
    console.error('path has to be a string');
  }
}

//  path: 'a.b[1].c[0]' => { id: 'a.b[].c[]'  dataIndex: [1,0] }
export function destructDataPath(path) {
  var id;
  var dataIndex;
  if (path === '#') {
    return {
      id: '#',
      dataIndex: []
    };
  }
  if (typeof path !== 'string') {
    throw Error("path " + path + " is not a string!!! Something wrong here");
  }
  var pattern = /\[[0-9]+\]/g;
  var matchList = path.match(pattern);
  if (!matchList) {
    id = path;
  } else {
    id = path.replace(pattern, '[]');
    dataIndex = matchList.map(function (item) {
      return Number(item.substring(1, item.length - 1));
    });
  }
  return {
    id: id,
    dataIndex: dataIndex
  };
}

// id: 'a.b[].c[]'  dataIndex: [1,0] =>  'a.b[1].c[0]'
export function getDataPath(id, dataIndex) {
  if (id === '#') {
    return id;
  }
  if (typeof id !== 'string') {
    throw Error("id " + id + " is not a string!!! Something wrong here");
  }
  var _id = id;
  if (Array.isArray(dataIndex)) {
    // const matches = id.match(/\[\]/g) || [];
    // const count = matches.length;
    dataIndex.forEach(function (item) {
      _id = _id.replace(/\[\]/, "[" + item + "]");
    });
  }
  return removeBrackets(_id);
}
export function isObjType(schema) {
  return schema && schema.type === 'object' && schema.fields;
}
export function isSectionType(schema) {
  return schema && schema.type === 'section' && schema.fields;
}
export function isListType(schema) {
  return schema && schema.type === 'array' && isObjType(schema.items) && schema["enum"] === undefined;
}
export function flattenSchema(_schema, name, parent, result) {
  if (_schema === void 0) {
    _schema = {};
  }
  if (name === void 0) {
    name = '#';
  }
  if (result === void 0) {
    result = {};
  }
  var schema = clone(_schema);
  var _name = name;
  if (!schema.$id) {
    schema.$id = _name;
  }
  var children = [];
  if (isObjType(schema)) {
    Object.entries(schema.fields).forEach(function (_ref) {
      var key = _ref[0],
        value = _ref[1];
      var _key = isListType(value) ? key + '[]' : key;
      var uniqueName = _name === '#' ? _key : _name + '.' + _key;
      children.push(uniqueName);
      flattenSchema(value, uniqueName, _name, result);
    });
    // schema.fields = {};
  }

  if (isSectionType(schema)) {
    Object.entries(schema.fields).forEach(function (_ref2) {
      var key = _ref2[0],
        value = _ref2[1];
      var _key = isListType(value) ? key + '[]' : key;
      var uniqueName = _name === '#' ? _key : _name + '.' + _key;
      children.push(uniqueName);
      flattenSchema(value, uniqueName, _name, result);
    });
    // schema.fields = {};
  }

  if (isListType(schema)) {
    Object.entries(schema.items.fields).forEach(function (_ref3) {
      var key = _ref3[0],
        value = _ref3[1];
      var _key = isListType(value) ? key + '[]' : key;
      var uniqueName = _name === '#' ? _key : _name + '.' + _key;
      children.push(uniqueName);
      flattenSchema(value, uniqueName, _name, result);
    });
    // schema.items.properties = {};
  }

  var rules = Array.isArray(schema.rules) ? [].concat(schema.rules) : [];
  if (['boolean', 'function', 'string'].indexOf(typeof schema.required) > -1) {
    rules.push({
      required: schema.required
    });
  }
  if (schema.type) {
    result[_name] = {
      parent: parent,
      schema: schema,
      children: children,
      rules: rules
    };
  }
  return result;
}

//////////   old

function stringContains(str, text) {
  return str.indexOf(text) > -1;
}
export var isObject = function isObject(a) {
  return stringContains(Object.prototype.toString.call(a), 'Object');
};

// function clone1(data) {
//   // data = functionToString(data);
//   try {
//     return JSON.parse(JSON.stringify(data));
//   } catch (e) {
//     return data;
//   }
// }

export var clone = cloneDeep;
// export const clone = clone1;

// export const functionToString = data => {
//   let result;
//   if (isObject(data)) {
//     result = {};
//     Object.keys(data).forEach(key => {
//       result[key] = functionToString(data[key]);
//     });
//     return result;
//   } else if (typeof data === 'function') {
//     return result.toString();
//   }
//   return data;
// };

// '3' => true, 3 => true, undefined => false
export function isLooselyNumber(num) {
  if (typeof num === 'number') return true;
  if (typeof num === 'string') {
    return !Number.isNaN(Number(num));
  }
  return false;
}
export function isCssLength(str) {
  if (typeof str !== 'string') return false;
  return str.match(/^([0-9])*(%|px|rem|em)$/i);
}
export function isDeepEqual(param1, param2) {
  if (param1 === undefined && param2 === undefined) return true;else if (param1 === undefined || param2 === undefined) return false;
  if (param1 === null && param2 === null) return true;else if (param1 === null || param2 === null) return false;else if (param1.constructor !== param2.constructor) return false;
  if (param1.constructor === Array) {
    if (param1.length !== param2.length) return false;
    for (var i = 0; i < param1.length; i++) {
      var _param1$i, _param1$i2;
      if (((_param1$i = param1[i]) === null || _param1$i === void 0 ? void 0 : _param1$i.constructor) === Array || ((_param1$i2 = param1[i]) === null || _param1$i2 === void 0 ? void 0 : _param1$i2.constructor) === Object) {
        if (!isDeepEqual(param1[i], param2[i])) return false;
      } else if (param1[i] !== param2[i]) return false;
    }
  } else if (param1.constructor === Object) {
    if (Object.keys(param1).length !== Object.keys(param2).length) return false;
    for (var _i = 0; _i < Object.keys(param1).length; _i++) {
      var _param1$key, _param1$key2;
      var key = Object.keys(param1)[_i];
      if (param1[key] && typeof param1[key] !== 'number' && (((_param1$key = param1[key]) === null || _param1$key === void 0 ? void 0 : _param1$key.constructor) === Array || ((_param1$key2 = param1[key]) === null || _param1$key2 === void 0 ? void 0 : _param1$key2.constructor) === Object)) {
        if (!isDeepEqual(param1[key], param2[key])) return false;
      } else if (param1[key] !== param2[key]) return false;
    }
  } else if (param1.constructor === String || param1.constructor === Number) {
    return param1 === param2;
  }
  return true;
}
export function getFormat(format) {
  var dateFormat;
  switch (format) {
    case 'date':
      dateFormat = 'YYYY-MM-DD';
      break;
    case 'time':
      dateFormat = 'HH:mm:ss';
      break;
    case 'dateTime':
      dateFormat = 'YYYY-MM-DD HH:mm:ss';
      break;
    case 'week':
      dateFormat = 'YYYY-w';
      break;
    case 'year':
      dateFormat = 'YYYY';
      break;
    case 'quarter':
      dateFormat = 'YYYY-Q';
      break;
    case 'month':
      dateFormat = 'YYYY-MM';
      break;
    default:
      // dateTime
      dateFormat = 'YYYY-MM-DD';
  }
  return dateFormat;
}
export function hasRepeat(list) {
  return list.find(function (x, i, self) {
    return i !== self.findIndex(function (y) {
      return JSON.stringify(x) === JSON.stringify(y);
    });
  });
}
export function combineSchema(propsSchema, uiSchema) {
  if (propsSchema === void 0) {
    propsSchema = {};
  }
  if (uiSchema === void 0) {
    uiSchema = {};
  }
  var propList = getChildren(propsSchema);
  var newList = propList.map(function (p) {
    var name = p.name;
    var _p$schema = p.schema,
      type = _p$schema.type,
      options = _p$schema["enum"],
      fields = _p$schema.fields,
      items = _p$schema.items;
    var isObj = type === 'object' && fields;
    var isArr = type === 'array' && items && !options;
    var ui = name && uiSchema[p.name];
    if (!ui) {
      return p;
    }
    if (isArr) {
      var newItems = combineSchema(items, ui.items || {});
      return _extends({}, p, {
        schema: _extends({}, p.schema, ui, {
          items: newItems
        })
      });
    }
    if (isObj) {
      var newSchema = combineSchema(p.schema, ui);
      return _extends({}, p, {
        schema: newSchema
      });
    }
    return _extends({}, p, {
      schema: _extends({}, p.schema, ui)
    });
  });
  var newObj = {};
  newList.forEach(function (s) {
    newObj[s.name] = s.schema;
  });
  var topLevelUi = {};
  Object.keys(uiSchema).forEach(function (key) {
    if (typeof key === 'string' && key.substring(0, 3) === 'ui:') {
      topLevelUi[key] = uiSchema[key];
    }
  });
  if (isEmpty(newObj)) {
    return _extends({}, propsSchema, topLevelUi);
  }
  return _extends({}, propsSchema, topLevelUi, {
    fields: newObj
  });
}
function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
function getChildren(schema) {
  if (!schema) return [];
  var fields = schema.fields,
    items = schema.items,
    type = schema.type;
  if (!fields && !items) {
    return [];
  }
  var schemaSubs = {};
  if (type === 'object') {
    schemaSubs = fields;
  }
  if (type === 'array') {
    schemaSubs = items;
  }
  return Object.keys(schemaSubs).map(function (name) {
    return {
      schema: schemaSubs[name],
      name: name
    };
  });
}
export function combine() {}
export var parseString = function parseString(string) {
  return Function('"use strict";return (' + string + ')')();
};
export var evaluateString = function evaluateString(string, formData, rootValue) {
  return Function("\"use strict\";\n    const rootValue = " + JSON.stringify(rootValue) + ";\n    const formData = " + JSON.stringify(formData) + ";\n    return (" + string + ")")();
};
export function isExpression(func) {
  // if (typeof func === 'function') {
  //   const funcString = func.toString();
  //   return (
  //     funcString.indexOf('formData') > -1 ||
  //     funcString.indexOf('rootValue') > -1
  //   );
  // }
  if (typeof func !== 'string') return false;
  func = func.replace(/\r|\n/g, " ");
  var pattern = /^{{(.+)}}$/;
  var reg1 = /^{{(function.+)}}$/;
  var reg2 = /^{{(.+=>.+)}}$/;
  if (typeof func === 'string' && func.match(pattern) && !func.match(reg1) && !func.match(reg2)) {
    return true;
  }
  return false;
}
export function parseSingleExpression(func, formData, dataPath, global) {
  if (formData === void 0) {
    formData = {};
  }
  var parentPath = getParentPath(dataPath);
  var parent = getValueByPath(formData, parentPath) || {};
  if (typeof func === 'string') {
    var funcBody = func.substring(2, func.length - 2).replace(/\r|\n/g, " ");
    var globalTag = "__G_L_O_B_A_L__";
    var str = "\n    return " + funcBody.replace(/\bformData\b/g, JSON.stringify(formData).replace(/\bglobal\b/g, globalTag)).replace(/\bglobal\b/g, JSON.stringify(global)).replace(new RegExp("\\b" + globalTag + "\\b", "g"), "global").replace(/rootValue/g, JSON.stringify(parent));
    try {
      return Function(str)();
    } catch (error) {
      console.log(error, func, dataPath);
      return func;
    }
    // const funcBody = func.substring(2, func.length - 2);
    // const match1 = /formData.([a-zA-Z0-9.$_\[\]]+)/g;
    // const match2 = /rootValue.([a-zA-Z0-9.$_\[\]]+)/g;
    // const str = `
    // return (${funcBody
    //   .replaceAll(match1, (v, m1) =>
    //     JSON.stringify(getValueByPath(formData, m1))
    //   )
    //   .replaceAll(match2, (v, m1) =>
    //     JSON.stringify(getValueByPath(parent, m1))
    //   )})`;
    // try {
    //   return Function(str)();
    // } catch (error) {
    //   return func;
    // }
  } else return func;
}
export var schemaContainsExpression = function schemaContainsExpression(schema, shallow) {
  if (shallow === void 0) {
    shallow = true;
  }
  if (isObject(schema)) {
    return Object.keys(schema).some(function (key) {
      var value = schema[key];
      if (typeof value === 'string') {
        return isExpression(value);
      } else if (typeof key === 'string' && key.toLowerCase().indexOf('props') > -1) {
        var propsObj = schema[key];
        if (isObject(propsObj)) {
          return Object.keys(propsObj).some(function (k) {
            return isExpression(propsObj[k]);
          });
        }
      } else if (!shallow && isObject(value)) {
        return schemaContainsExpression(value, false);
      }
      return false;
    });
  } else {
    return false;
  }
};
export var parseAllExpression = function parseAllExpression(_schema, formData, dataPath, global) {
  if (global === void 0) {
    global = {};
  }
  var schema = clone(_schema);
  Object.keys(schema).forEach(function (key) {
    var value = schema[key];
    if (isExpression(value)) {
      schema[key] = parseSingleExpression(value, formData, dataPath, global);
    }
    if (typeof key === 'string' && key.toLowerCase().indexOf('props') > -1) {
      var propsObj = schema[key];
      if (isObject(propsObj)) {
        Object.keys(propsObj).forEach(function (k) {
          schema[key][k] = parseSingleExpression(propsObj[k], formData, dataPath, global);
        });
      }
    }
  });
  return schema;
};
export function isFunctionSchema(schema) {
  return Object.keys(schema).some(function (key) {
    if (typeof schema[key] === 'function') {
      return true;
    } else if (typeof schema[key] === 'string') {
      return isExpression(schema[key]);
    } else if (typeof schema[key] === 'object') {
      return isFunctionSchema(schema[key]);
    } else {
      return false;
    }
  });
}
export var getParentProps = function getParentProps(propName, id, flatten) {
  try {
    var item = flatten[id];
    if (item.schema[propName] !== undefined) return item.schema[propName];
    if (item && item.parent) {
      var parentSchema = flatten[item.parent].schema;
      if (parentSchema[propName] !== undefined) {
        return parentSchema[propName];
      } else {
        return getParentProps(propName, item.parent, flatten);
      }
    }
  } catch (error) {
    return undefined;
  }
};
export var getSaveNumber = function getSaveNumber() {
  var searchStr = localStorage.getItem('SAVES');
  if (searchStr) {
    try {
      var saves = JSON.parse(searchStr);
      var length = saves.length;
      if (length) return length + 1;
    } catch (error) {
      return 1;
    }
  } else {
    return 1;
  }
};
export function looseJsonParse(obj) {
  return Function('"use strict";return (' + obj + ')')();
}
function getChildren2(schema) {
  if (!schema) return [];
  var fields = schema.fields,
    items = schema.items,
    type = schema.type;
  if (!fields && !items) {
    return [];
  }
  var schemaSubs = {};
  if (type === 'object') {
    schemaSubs = fields;
  }
  if (type === 'array') {
    schemaSubs = items.fields;
  }
  return Object.keys(schemaSubs).map(function (name) {
    return {
      schema: schemaSubs[name],
      name: name
    };
  });
}
export var oldSchemaToNew = function oldSchemaToNew(schema) {
  if (schema && schema.propsSchema) {
    var propsSchema = schema.propsSchema,
      rest = _objectWithoutPropertiesLoose(schema, _excluded);
    return _extends({
      schema: propsSchema
    }, rest);
  }
  return schema;
};
export var newSchemaToOld = function newSchemaToOld(setting) {
  if (setting && setting.schema) {
    var schema = setting.schema,
      rest = _objectWithoutPropertiesLoose(setting, _excluded2);
    return _extends({
      propsSchema: schema
    }, rest);
  }
  return setting;
};

// from FR

export var getEnum = function getEnum(schema) {
  if (!schema) return undefined;
  var itemEnum = schema && schema.items && schema.items["enum"];
  var schemaEnum = schema && schema["enum"];
  return itemEnum ? itemEnum : schemaEnum;
};
export var getArray = function getArray(arr, defaultValue) {
  if (defaultValue === void 0) {
    defaultValue = [];
  }
  if (Array.isArray(arr)) return arr;
  return defaultValue;
};
export var isEmail = function isEmail(value) {
  var regex = '^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$';
  if (value && new RegExp(regex).test(value)) {
    return true;
  }
  return false;
};
export function defaultGetValueFromEvent(valuePropName) {
  var event = arguments.length <= 1 ? undefined : arguments[1];
  if (event && event.target && valuePropName in event.target) {
    return event.target[valuePropName];
  }
  return event;
}
export var getKeyFromPath = function getKeyFromPath(path) {
  try {
    var keyList = path.split('.');
    var last = keyList.slice(-1)[0];
    return last;
  } catch (error) {
    console.error(error, 'getKeyFromPath');
    return '';
  }
};
export var getDisplayValue = function getDisplayValue(value, schema) {
  if (typeof value === 'boolean') {
    return value ? 'yes' : 'no';
  }
  if (isObjType(schema) || isListType(schema)) {
    return '-';
  }
  if (Array.isArray(schema["enum"]) && Array.isArray(schema.enumNames)) {
    try {
      return schema.enumNames[schema["enum"].indexOf(value)];
    } catch (error) {
      return value;
    }
  }
  return value;
};
export var removeEmptyItemFromList = function removeEmptyItemFromList(formData) {
  var result = {};
  if (isObject(formData)) {
    Object.keys(formData).forEach(function (key) {
      result[key] = removeEmptyItemFromList(formData[key]);
    });
  } else if (Array.isArray(formData)) {
    result = formData.filter(function (item) {
      if (item && JSON.stringify(item) !== '{}') {
        return true;
      }
      return false;
    });
  } else {
    result = formData;
  }
  return result;
};
export var getDescriptorFromSchema = function getDescriptorFromSchema(_ref4) {
  var schema = _ref4.schema,
    _ref4$isRequired = _ref4.isRequired,
    isRequired = _ref4$isRequired === void 0 ? true : _ref4$isRequired;
  var _schema = cloneDeep(schema);
  if (_schema.type != 'object' && _schema.type != 'array' && _schema.type != 'section' && _schema.type != 'number' && _schema.type != 'boolean') {
    _schema.type = 'string';
  }
  var result = {};
  var singleResult = {};
  if (_schema.hidden === true) return result;
  if (isObjType(_schema) || isSectionType(_schema)) {
    result.type = 'object';
    if (isRequired && _schema.required === true) {
      result.required = true;
    }
    result.fields = {};
    Object.keys(_schema.fields).forEach(function (key) {
      var item = _schema.fields[key];
      if (Array.isArray(_schema.required) && _schema.required.indexOf(key) > -1) {
        item.required = true;
      }
      if (isSectionType(item)) {
        var sFields = getDescriptorFromSchema({
          schema: item,
          isRequired: item.required
        });
        result.fields = sFields.fields;
      } else {
        result.fields[key] = getDescriptorFromSchema({
          schema: item,
          isRequired: item.required
        });
      }
    });
  } else if (isListType(_schema)) {
    result.type = 'array';
    if (isRequired && _schema.required === true) {
      result.required = true;
    }
    if (_schema.min) {
      result.min = _schema.min;
    }
    if (_schema.max) {
      result.max = _schema.max;
    }
    result.defaultField = {
      type: 'object',
      fields: {}
    };
    Object.keys(_schema.items.fields).forEach(function (key) {
      var item = _schema.items.fields[key];
      if (Array.isArray(_schema.required) && _schema.required.indexOf(key) > -1) {
        item.required = true;
      }
      result.defaultField.fields[key] = getDescriptorFromSchema({
        schema: item,
        isRequired: isRequired
      });
    });
  } else {
    var processRule = function processRule(item) {
      if (_schema.type) return _extends({}, item, {
        type: _schema.type
      });
      if (item.pattern && typeof item.pattern === 'string') {
        return _extends({}, item, {
          pattern: new RegExp(item.pattern)
        });
      }
      return item;
    };
    var required = _schema.required,
      rest = _objectWithoutPropertiesLoose(_schema, _excluded3);
    ['type', 'pattern', 'min', 'max', 'len'].forEach(function (key) {
      if (Object.keys(rest).indexOf(key) > -1) {
        singleResult[key] = rest[key];
      }
    });
    switch (_schema.type) {
      case 'range':
        singleResult.type = 'array';
        break;
      case 'html':
        singleResult.type = 'string';
        break;
      default:
        break;
    }
    switch (_schema.format) {
      case 'email':
      case 'url':
        singleResult.type = _schema.format;
        break;
      default:
        break;
    }
    var requiredRule;
    if (isRequired && _schema.required === true) {
      requiredRule = {
        required: true
      };
    }
    if (_schema.rules) {
      if (Array.isArray(_schema.rules)) {
        var _rules = [];
        _schema.rules.forEach(function (item) {
          if (item.required === true) {
            if (isRequired) {
              requiredRule = item;
            }
          } else {
            _rules.push(processRule(item));
          }
        });
        result = [singleResult].concat(_rules);
      } else if (isObject(_schema.rules)) {
        result = [singleResult, processRule(_schema.rules)];
      } else {
        result = singleResult;
      }
    } else {
      result = singleResult;
    }
    if (requiredRule) {
      if (Array.isArray(result)) {
        result.push(requiredRule);
      } else if (isObject(result)) {
        result = [result, requiredRule];
      }
    }
    if (_schema.format === 'image') {
      var imgValidator = {
        validator: function validator(rule, value) {
          var pattern = /([/|.|w|s|-])*.(jpg|gif|png|bmp|apng|webp|jpeg|json)/;
          if (!value) return true;
          return !!pattern.exec(value) || isUrl(value);
        },
        message: '${title} không phải là hình ảnh'
      };
      if (Array.isArray(result)) {
        result.push(imgValidator);
      } else if (isObject(result)) {
        result = [result, imgValidator];
      }
    }
  }
  return result;
};

// "list.1.userName" => "list[1].userName"
export var formatPathFromValidator = function formatPathFromValidator(err) {
  var errArr = err.split('.');
  return errArr.map(function (item) {
    if (isNaN(Number(item))) {
      return item;
    } else {
      return "[" + item + "]";
    }
  }).reduce(function (a, b) {
    if (b[0] === '[' || a === '') {
      return a + b;
    } else {
      return a + '.' + b;
    }
  }, '');
};

// schema = {
//   type: 'object',
//   properties: {
//     x: {
//       type: 'object',
//       properties: {
//         y: {
//           type: 'string',
//           required: true,
//         },
//       },
//     },
//   },
// };
// path = 'x.y'
// return {required: true, message?: 'xxxx'}
export var isPathRequired = function isPathRequired(path, schema) {
  var pathArr = path.split('.');
  while (pathArr.length > 0) {
    var _pathArr = pathArr,
      _path = _pathArr[0],
      rest = _pathArr.slice(1);
    _path = _path.split('[')[0];
    var childSchema = void 0;
    if (isObjType(schema) || isSectionType(schema)) {
      childSchema = schema.fields[_path];
    } else if (isListType(schema)) {
      childSchema = schema.items.properties[_path];
    }
    pathArr = rest;
    if (childSchema) {
      return isPathRequired(rest.join('.'), childSchema);
    }
    var result = {
      required: false
    };
    if (schema.required === true) {
      result.required = true;
    }
    if (schema.rules) {
      var requiredItem = schema.rules.find(function (item) {
        return item.required;
      });
      if (requiredItem) {
        result = requiredItem;
      }
    }
    return result;
  }
};
export var getSchemaByPath = function getSchemaByPath(path, schema) {
  var pathArr = path.split('.');
  while (pathArr.length > 0) {
    var _pathArr2 = pathArr,
      _path = _pathArr2[0],
      rest = _pathArr2.slice(1);
    _path = _path.split('[')[0];
    var childSchema = void 0;
    if (isObjType(schema) || isSectionType(schema)) {
      childSchema = schema.fields[_path];
    } else if (isListType(schema)) {
      childSchema = schema.items.properties[_path];
    }
    pathArr = rest;
    if (childSchema) {
      return getSchemaByPath(rest.join('.'), childSchema);
    } else {
      return schema;
    }
  }
};
export var generateDataSkeleton = function generateDataSkeleton(schema) {
  var result = {};
  if (isObjType(schema)) {
    Object.keys(schema.fields).forEach(function (key) {
      var childSchema = schema.fields[key];
      var childResult = generateDataSkeleton(childSchema);
      result[key] = childResult;
    });
  } else if (schema["default"] !== undefined) {
    result = clone(schema["default"]);
  } else if (schema.type === 'boolean') {
    result = false;
  } else {
    result = undefined;
  }
  return result;
};
export var translateMessage = function translateMessage(msg, schema) {
  if (typeof msg !== 'string') {
    return '';
  }
  if (!schema) return msg;
  msg = msg.replace('${title}', schema.title);
  msg = msg.replace('${type}', schema.format || schema.type);
  if (schema.min) {
    msg = msg.replace('${min}', schema.min);
  }
  if (schema.max) {
    msg = msg.replace('${max}', schema.max);
  }
  if (schema.rules) {
    var minRule = schema.rules.find(function (r) {
      return r.min !== undefined;
    });
    if (minRule) {
      msg = msg.replace('${min}', minRule.min);
    }
    var maxRule = schema.rules.find(function (r) {
      return r.max !== undefined;
    });
    if (maxRule) {
      msg = msg.replace('${max}', maxRule.max);
    }
    var lenRule = schema.rules.find(function (r) {
      return r.len !== undefined;
    });
    if (lenRule) {
      msg = msg.replace('${len}', lenRule.len);
    }
    var patternRule = schema.rules.find(function (r) {
      return r.pattern !== undefined;
    });
    if (patternRule) {
      msg = msg.replace('${pattern}', patternRule.pattern);
    }
  }
  return msg;
};

// "objectName": {
//   "type": "object",
//   "properties": {

//   }
// }

// "listName": {
//   "type": "array",
//   "items": {
//     "type": "object",
//     "properties": {

//     }
//   }
// }

var changeSchema = function changeSchema(_schema, singleChange) {
  var schema = clone(_schema);
  schema = singleChange(schema);
  if (isObjType(schema) || isSectionType(schema)) {
    var requiredKeys = [];
    if (Array.isArray(schema.required)) {
      requiredKeys = schema.required;
      delete schema.required;
    }
    Object.keys(schema.fields).forEach(function (key) {
      var item = schema.fields[key];
      if (requiredKeys.indexOf(key) > -1) {
        item.required = true;
      }
      schema.fields[key] = changeSchema(item, singleChange);
    });
  } else if (isListType(schema)) {
    Object.keys(schema.items.fields).forEach(function (key) {
      var item = schema.items.fields[key];
      schema.items.fields[key] = changeSchema(item, singleChange);
    });
  }
  return schema;
};
export var updateSchemaToNewVersion = function updateSchemaToNewVersion(schema) {
  return changeSchema(schema, updateSingleSchema);
};
var updateSingleSchema = function updateSingleSchema(schema) {
  try {
    schema.rules = schema.rules || [];
    schema.props = schema.props || {};
    if (schema['ui:options']) {
      schema.props = schema['ui:options'];
      delete schema['ui:options'];
    }
    if (schema.pattern) {
      var validItem = {
        pattern: schema.pattern
      };
      if (schema.message && schema.message.pattern) {
        validItem.message = schema.message.pattern;
      }
      schema.rules.push(validItem);
      delete schema.pattern;
      delete schema.message;
    }
    // min / max
    if (schema.minLength) {
      schema.min = schema.minLength;
      delete schema.minLength;
    }
    if (schema.maxLength) {
      schema.max = schema.maxLength;
      delete schema.maxLength;
    }
    if (schema.minItems) {
      schema.min = schema.minItems;
      delete schema.minItems;
    }
    if (schema.maxItems) {
      schema.max = schema.maxItems;
      delete schema.maxItems;
    }
    if (schema.step) {
      schema.props.step = schema.step;
      delete schema.step;
    }
    // ui:xxx
    if (schema['ui:className']) {
      schema.className = schema['ui:className'];
      delete schema['ui:className'];
    }
    if (schema['ui:hidden']) {
      schema.hidden = schema['ui:hidden'];
      delete schema['ui:hidden'];
    }
    if (schema['ui:readonly']) {
      schema.readOnly = schema['ui:readonly'];
      delete schema['ui:readonly'];
    }
    if (schema['ui:disabled']) {
      schema.disabled = schema['ui:disabled'];
      delete schema['ui:disabled'];
    }
    if (schema['ui:width']) {
      schema.width = schema['ui:width'];
      delete schema['ui:width'];
    }
    if (schema['ui:labelWidth']) {
      schema.labelWidth = schema['ui:labelWidth'];
      delete schema['ui:labelWidth'];
    }
    if (schema.rules && schema.rules.length === 0) {
      delete schema.rules;
    }
    return schema;
  } catch (error) {
    console.error('Old SCHEMA conversion failed!', error);
    return schema;
  }
};

// export const parseExpression = (schema, formData) => {
//   let schema1 = parseRootValue(schema);
//   let schema2 = replaceParseValue(schema1);
// };

export var parseFunctionString = function parseFunctionString(string) {
  if (typeof string !== 'string') return false;
  var reg1 = /^{{(function.+)}}$/;
  var reg2 = /^{{(.+=>.+)}}$/;
  if (string.match(reg1)) {
    return string.match(reg1)[1];
  }
  if (string.match(reg2)) {
    return string.match(reg2)[1];
  }
  return false;
};
export var completeSchemaWithTheme = function completeSchemaWithTheme(schema, theme) {
  if (schema === void 0) {
    schema = {};
  }
  if (theme === void 0) {
    theme = {};
  }
  var result = {};
  if (isObject(schema)) {
    if (schema.theme && theme[schema.theme]) {
      result = _extends({}, schema, theme[schema.theme]);
    }
    Object.keys(schema).forEach(function (key) {
      result[key] = completeSchemaWithTheme(schema[key], theme);
    });
  } else {
    result = schema;
  }
  return result;
};
export var cleanEmpty = function cleanEmpty(obj) {
  if (Array.isArray(obj)) {
    return obj.map(function (v) {
      return v && isObject(v) ? cleanEmpty(v) : v;
    }).filter(function (v) {
      return !(v == undefined);
    });
  } else if (isObject(obj)) {
    return Object.entries(obj).map(function (_ref5) {
      var k = _ref5[0],
        v = _ref5[1];
      return [k, v && isObject(v) ? cleanEmpty(v) : v];
    }).reduce(function (a, _ref6) {
      var k = _ref6[0],
        v = _ref6[1];
      return v == undefined ? a : (a[k] = v, a);
    }, {});
  } else {
    return obj;
  }
};
export var removeHiddenFromResult = function removeHiddenFromResult(data, flatten) {
  Object.keys(flatten).forEach(function (key) {
    var hidden = flatten[key].schema && flatten[key].schema.hidden === true;
    if (get(data, key) !== undefined && hidden) {
      set(data, key, undefined);
    }
  });
  return data;
};
export var getFileResponseErrorMessage = function getFileResponseErrorMessage(file) {
  var _file$error, _file$error2, _file$error3;
  var result = "";
  var errorCode = (_file$error = file.error) === null || _file$error === void 0 ? void 0 : _file$error.status;
  var errorMsg = ((_file$error2 = file.error) === null || _file$error2 === void 0 ? void 0 : _file$error2.reason) || ((_file$error3 = file.error) === null || _file$error3 === void 0 ? void 0 : _file$error3.message) || "";
  if (errorMsg) {
    errorMsg = translate(errorMsg);
  }
  if (isString(file.response)) {
    var _file$response;
    var matchedResponseTitles = ((_file$response = file.response) === null || _file$response === void 0 ? void 0 : _file$response.match("\<title\>\(.+)<\/title\>")) || [];
    var responseTitle = matchedResponseTitles[1];
    if (responseTitle) {
      result = errorMsg ? errorMsg + " \r\n " + responseTitle : responseTitle;
    }
  } else {
    var _file$response2;
    result = ((_file$response2 = file.response) === null || _file$response2 === void 0 ? void 0 : _file$response2.error.message) || errorMsg;
  }
  if (errorCode === 413) {
    result = translate("dgplatform_request_oversized");
  }
  return result;
};